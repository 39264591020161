
.handle-box[data-v-5dc2f136] {
    margin-bottom: 20px;
}
.table[data-v-5dc2f136] {
    width: 100%;
    font-size: 14px;
}
.red[data-v-5dc2f136] {
    color: #ff0000;
}
.mr10[data-v-5dc2f136] {
    margin-right: 10px;
}
